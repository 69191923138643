import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const IndexPage = () => (
  <Layout>
    <Seo />
    <div>
      <div className={styles.textCenter}>
        <StaticImage
          src="../images/logo.jpg"
          loading="eager"
          width={200}
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          style={{ marginBottom: `var(--space-3)`, alignSelf: 'center' }}
        />
      </div>
      <p>Радіо - це не просто музика і ведучі, чи новини і програми. Радіо - це гармонійне поєднання усіх складових. Правильне поєднання насправді спростить вам роботу і зробить вас зрозумілішими та ріднішими для слухача. А це саме те. що потрібно для успіху.</p>
      <p>Якщо Ви прагнете більшого, якщо все ніби робите правильно, а  щось не так - є ті хто допоможуть!  📻</p>
      <p>Ми - радіоконсультанти "RealRadio" і ми допоможемо зрозуміти чого потребує Ваша цільова аудиторія, спланувати гармонійний і якісний  ефір та вплести у нього органічно новини та програми і шоу.</p>
      <p>"RealRadio" пропонує тренінги на тему:</p>
      <ul>
        <li><b>Програмування ефіру</b> (коли час на новини? Коли на рекламу? Коли на шоу? Скільки виходів потрібно ведучому?)</li>
        <li><b>Музичний формат і формування плейлиста</b></li>
        <li><b>Тонкощі</b> роботи з <b>Powergold</b> і Sound Empire</li>
        <li><b>Як стати успішним ведучим?</b></li>
        <li><b>Новини на радіо</b> (тривалість, наповненість, формат, де шукати теми і як писати тексти так, щоб їх слухали)</li>
        <li><b>Внутрішнє і зовнішнє</b> промування станції</li>
        <li><b>Соцмережі.</b> Як використовувати, щоб вони принесли максимальну користь? І ще багато інших цікавих тем в роботі радіо.</li>
      </ul>
      <p>Ми працюємо не на теоретичних прикладах, а “розбираємо” Ваш ефір і навчимо, як завтра Ви можете стати #1!</p>
      <p className={styles.textCenter}><b>Про нас</b></p>
      <div>
        <StaticImage
          src="../images/olia.jpg"
          loading="eager"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          className="picture"
        />
        <div><b>Оля Боровець.</b></div>
        <p>У медіа працює 30 років. Перший її досвід був ще о шкільному віці, коли вела дитячу програму на обласному радіо. А от перший дорослий досвід отримала на на радіо у 1995 році, коли почало мовлення радіо Люкс. Працювала на інших місцевих радіостанціях та у Києві на радіо “Ніко”. Має великий досвід ведучої ефіру та журналіста в новинах.  Великий досвід керівника редакції новин. У 2009 працювала  керівником редакції новин радіо 4U(філія польської RMF FM), завдяки цьому отримала європейський радійний досвід. У 2011-му році самостійно сформувала редакцію новин і новинну ефірну сітку для загальноукраїнської новинної радіостанції “Радіо 24”. Має досвід проведення цілодобових радіомарафонів під час Помаранчевої революції (2004 рік), революції Гідності (2013-2014), початку повномасштабного вторгнення Росії (2022).
        У 2019 - 2022 працювала бренд менеджером та креативним директором Люкс ФМ (Львів). Розробила сотні успішних ефірних проектів. Паралельно писала сценарії ефірних роликів та створювала музичні та розважальні програми для ефіру.
        </p>
      </div>
      <div>
        <StaticImage
          src="../images/mike.jpg"
          loading="eager"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt=""
          className="picture"
        />
        <div><b>Михайло Безпалько.</b></div>
        <p>Працює 15 років у медіа в сфері радіо. Розпочав свою кар’єру з посади звукорежисера на львівському РадіоМан(філія польської RMF FM). Згодом працював на західно-українській радіо-мережі Radio 4U, де обіймав посаду звукорежисера і заступника муз редактора. З 2009 і до березня цього року працював у медіа-холдингу Люкс. Обіймав посади музичного редактора Радіо 24 та музичного редактора  Люкс ФМ, а з 2017 і до травня 2022-го  працював на посаді програмного директора Люкс ФМ (Львів). Найбільшими своїми досягненнями на посаді вважає: створення згуртованого колективу, втілення в життя багатьох успішних проектів та виведення радіостанції на лідируючі позиції у Львові. </p>
      </div>
      <p><i>Зв’язатись з нами.</i></p>
      <div><b>Контакти:</b></div>
      <div><b>e-mail: </b><a href={`mailto:realradio.ua@gmail.com`}>realradio.ua@gmail.com</a></div>
      <div><b>тел.: </b>+380961022959</div>
      <div><a href="https://www.facebook.com/realradio.ua/" target="_blank" rel="noreferrer">facebook</a></div>
      <div><a href="https://www.instagram.com/realradio.ua/" target="_blank" rel="noreferrer">instagram</a></div>
    </div>
  </Layout>
)

export default IndexPage
